<template>
	<div>
		<div v-if="images.length" class="mr-2 d-flex" style="gap: 1rem">
			<div
				v-b-tooltip.hover.right="'Images'"
				class="flex-shrink-1"
				style="position: relative; padding: 4px"
				@click="openImages"
			>
				<div class="overlay">+{{ images.length }}</div>
				<img :src="images[0]" thumbnail class="image img-thumbnail" />
			</div>
		</div>
		<div v-else class="text-muted" style="font-size: 12px">
			No images
		</div>

		<div>
			<b-modal
				v-model="showImage"
				size="lg"
				title="Report image"
				centered
				hide-footer
				@hidden="isModalReady = false"
			>
				<div v-if="isModalReady">
					<swiper
						v-if="images.length"
						class="swiper-responsive-breakpoints py-2"
						:options="swiperOptions"
					>
						<swiper-slide
							v-for="(image, index) in images"
							:key="index"
							class="text-center"
						>
							<b-img :src="image" fluid thumbnail />
							<!-- <a :href="download(image)">Download</a> -->
						</swiper-slide>

						<div
							v-if="images.length > 1"
							slot="button-next"
							class="swiper-button-next"
						/>
						<div
							v-if="images.length > 1"
							slot="button-prev"
							class="swiper-button-prev"
						/>
					</swiper>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
import { BModal, BImg, VBTooltip } from 'bootstrap-vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
	name: 'TableImages',

	components: { BModal, BImg, Swiper, SwiperSlide },

	directives: {
		'b-tooltip': VBTooltip,
	},

	props: {
		images: {
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		openModalKey: 0,
		isModalReady: false,
		showImage: false,
		swiperOptions: {
			slidesPerView: 1,
			spaceBetween: 60,
			allowTouchMove: false,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		},
	}),

	methods: {
		openImages() {
			this.showImage = true;

			// eslint-disable-next-line no-return-assign
			setTimeout(() => (this.isModalReady = true), 50);
		},

		// download(img) {
		// 	const imgId = img.split('/')[4];
		// 	return `'http://pwp2.vns.agency/media/download/${imgId}`;
		// }
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

.swiper-button-prev,
.swiper-button-next {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	width: 44px;
}

.swiper-button-prev:after {
	background-position: 1px 2px;
	background-size: 40px;
}

.swiper-button-next:after {
	background-position: 4px 3px;
	background-size: 40px;
}

.swiper-slide {
	height: 500px;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.image {
	display: block;
	max-width: 35px;
	width: 35px;
	height: 50px;
}

.overlay {
	background: #333;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	border-radius: 4px;
	top: -1px;
	left: 0;
	font-size: 18px;
	color: #fff;
	display: grid;
	cursor: pointer;
	place-items: center;
}
</style>
