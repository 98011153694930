<template>
	<div class="">
		<b-button v-b-tooltip.hover.top="'Map'" size="sm" variant="outline-primary" class="btn-icon" @click="openMap">
			<feather-icon icon="MapPinIcon" />
		</b-button>

		<b-modal v-model="showMap" size="lg" title="Report location" centered hide-footer @hidden="ready = false">
			<b-card v-if="ready" class="shadow-none" no-body>
				<!-- <h4 class="text-center mb-1">{{ title }}</h4> -->
				<div class="d-flex" style="gap: 1rem">
					<p class="d-flex align-items-center"><span class="hint-square green"></span> Project location</p>
					<p class="d-flex align-items-center"><span class="hint-square blue"></span> Report location</p>
				</div>
				<l-map :zoom="zoom" :center="loc">
					<l-tile-layer :url="url" />
					<l-marker :lat-lng="loc"> </l-marker>
					<l-marker :lat-lng="project" :icon="icon"> </l-marker>
				</l-map>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
import { BCard, BModal, BButton, VBTooltip } from 'bootstrap-vue';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
	name: 'TableMap',

	components: {
		LMap,
		LTileLayer,
		LMarker,

		BCard,
		BModal,
		BButton
	},

	directives: {
		'b-tooltip': VBTooltip
	},

	props: {
		title: {
			type: String,
			default: ''
		},
		loc: {
			type: Array,
			default: () => [15.354880290596578, 44.174212608591695]
		},
		project: {
			type: Array,
			default: () => [15.354880290596578, 44.174212608591695]
		}
	},

	data() {
		return {
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			zoom: 10,
			ready: false,

			showMap: false
		};
	},

	computed: {
		icon() {
			return L.icon({
				// eslint-disable-next-line global-require
				shadowUrl: require('@/assets/images/marker-shadow.png'),
				// eslint-disable-next-line global-require
				iconUrl: require('@/assets/images/map-g.png'),
				iconSize: [20, 41],
				iconAnchor: [20, 20]
			});
		}
	},

	methods: {
		openMap() {
			this.showMap = true;
			// eslint-disable-next-line no-return-assign
			setTimeout(() => (this.ready = true), 50);
		}
	}
};
</script>

<style lang="scss">
.vue2leaflet-map {
	&.leaflet-container {
		height: 320px;
	}
}

.hint-square {
	display: block;
	width: 12px;
	height: 12px;
	margin-left: 3px;
	margin-right: 6px;
	box-shadow: -1px 2px 6px #ababab;
	border-radius: 4px;
}

.green {
	background: #009145;
}

.blue {
	background: #2c83cb;
}
</style>
