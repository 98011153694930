<template>
	<div>
		<h3 class="pb-1">{{ item.name }}</h3>
		<base-table :items="items" :headers="headers" table-top paginate class="shadow-none border-secondary">
			<template #cell(images)="row">
				<table-images :images="row.item.images"></table-images>
			</template>
			<template #cell(map)="row">
				<table-map :loc="row.item.map"></table-map>
			</template>
		</base-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseTable from '@/components/BaseTable.vue';
import TableImages from '@/components/TableImages.vue';
import TableMap from '@/components/TableMap.vue';

export default {
	name: 'ItemDetails',

	components: { BaseTable, TableImages, TableMap },

	props: {
		item: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'executed_quantity', sortable: true },
			{ key: 'day_date', sortable: true },
			{ key: 'images', sortable: false },
			{ key: 'map', sortable: false }
		]
	}),

	computed: {
		...mapGetters('items', ['itemReports']),

		items() {
			return this.itemReports(this.item.id);
		}
	}
};
</script>

<style lang="scss" scoped></style>
