<template>
	<div class="">
		<b-modal v-model="itemInfo" size="lg" :title="modalTitle" hide-footer centered>
			<Details :item="currentItem" />
		</b-modal>

		<base-table :headers="headers" :items="items" table-top paginate>
			<template #action>
				<loading-btn :loading="loading" variant="primary" @click="exportExcel">Export Excel</loading-btn>
			</template>
			<template #cell(quantity)="data">
				<span> {{ data.value }} {{ data.item.unit }} </span>
			</template>
			<template #cell(executed_quantity)="data">
				<span> {{ calcQuantity(data.item) }} {{ data.item.unit }} </span>
			</template>
			<template #cell(progress)="data">
				<span>{{ data.item.progress }}%</span>
				<b-progress height="1.4rem" show-value>
					<b-progress-bar :value="data.item.progress" :label="`${data.item.progress}%`" />
				</b-progress>
			</template>
			<template #cell(actions)="data">
				<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="showItemInfo(data.item)">
					<span class="align-middle">View </span>
				</b-button>
			</template>
		</base-table>
	</div>
</template>

<script>
import store from '@/store';
import { BProgress, BProgressBar, BButton, BModal } from 'bootstrap-vue';

import api from '@/utils/api';
import BaseTable from '@/components/BaseTable.vue';
import LoadingBtn from '@/components/LoadingBtn.vue';
import Details from './Details.vue';

export default {
	name: 'ItemIndex',

	components: {
		BModal,
		BProgress,
		BProgressBar,
		BButton,
		Details,
		BaseTable,
		LoadingBtn
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', sortable: true },
			{ key: 'quantity', label: 'Contract Quantity', sortable: true },
			{ key: 'executed_quantity', label: 'Executed Quantity', sortable: true },
			{ key: 'progress' },
			{ key: 'actions' }
		],

		items: [],

		loading: false,
		itemInfo: false,
		currentItem: undefined
	}),

	computed: {
		modalTitle() {
			return this.currentItem?.name;
		}
	},

	created() {
		this.fetchItems();
	},

	methods: {
		showItemInfo(item) {
			this.currentItem = item;
			this.itemInfo = true;
		},

		async fetchItems() {
			try {
				this.items = await store.dispatch('projects/getProjectItems', this.$route.params.id);
			} catch (error) {
				console.error(error);
			}
		},

		calcProgress(item) {
			const done = this.getItemReports(item.id).reduce((prev, cur) => prev + parseInt(cur.executed_quantity, 10), 0);
			const val = ((done / item.quantity) * 100).toFixed(0);
			return !isNaN(val) ? val : 0;
		},

		getItemReports(id) {
			return this.$store.getters['items/itemReports'](id);
		},

		calcQuantity(item) {
			return this.getItemReports(item.id).reduce((prev, cur) => prev + parseFloat(cur.executed_quantity), 0);
		},

		exportExcel() {
			this.loading = true;

			api.getFile(`projects/${this.$route.params.id}/items-export`)
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						})
					);

					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'project-items');
					document.body.appendChild(link);
					link.click();
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.loading = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.form-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
